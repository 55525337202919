'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var tslib_es6 = require('../node_modules/tslib/tslib.es6.mjs.js');

var concatBlocksAndDraftBlockInTemplateResponse = function (template) {
  var _a, _b, _c, _d, _e;
  var newTemplate = tslib_es6.__assign({}, template);
  if (template.blocks || template.draft_blocks) {
    var data = tslib_es6.__spreadArray(tslib_es6.__spreadArray([], (_b = (_a = template.blocks) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : [], true), (_d = (_c = template.draft_blocks) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : [], true);
    template.blocks = {
      data: data
    };
    delete template.blocks;
  }
  if (template.block_list || template.draft_block_list) {
    var blockList = tslib_es6.__spreadArray(tslib_es6.__spreadArray([], template.block_list, true), (_e = template.draft_block_list) !== null && _e !== void 0 ? _e : [], true);
    template.block_list = blockList;
    delete template.draft_block_list;
  }
  return newTemplate;
};

exports.concatBlocksAndDraftBlockInTemplateResponse = concatBlocksAndDraftBlockInTemplateResponse;
